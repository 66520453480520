.list {
    display: flex;
    list-style: none;
    align-items: center;
}

.item {
    margin-right: 1em;
}

.item a {
    color: #FFF;
    text-decoration: none;
}

.item a:hover {
    color: #FFBB33;
}

.img {
    height: 35px;
}

.imgDrawer {
    background-color: #222;
}

.drawer {
    width: 250px;
    height: 100%;
}
