.container {
    /*width: 1200px;*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*margin: 0 auto;*/
    /*flex-wrap: wrap;*/


}

/*.min-height {*/
/*    min-height: 75%;*/
/*}*/

.start {
    justify-content: flex-start;
}

.column {
    flex-direction: column;
    justify-content: flex-start;
}

.full{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
    padding-top: 68px;
    padding-left: 5px;
    padding-right: 5px;
}
