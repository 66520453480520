.options {
    background-color: #202b53;
    width: 100%;
    padding: 10px;
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.optionsInfos {
    display: flex;
    align-items: center;
}

.options p {
    margin-right: 5px;
    margin-left: 20px;
    color: #FFFFFF;
}

.select {
    border-bottom-color: #efefef;
}

.h2_header {
    font-family: 'Tahoma', sans-serif;
    font-weight: lighter;
    color: #4D4949A8;
    margin-bottom: 10px;
    margin-top: 10px;
}
