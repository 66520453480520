* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Monospace', sans-serif;
}

html, body, root {
  background-color: #efefef;
  height: 100%;

}
