.loadingPage {
    display: flex;
    margin: 0 auto;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.loadingSize {
    background: transparent;
    display: flex;
    margin-left: 5px;
    height: 10px;
}


@media (max-width: 899px) {
    /* CSS rules for screens smaller than 900px */
    .loadingImage {
        display: flex;
        width: 100px;
    }
}

@media (min-width: 900px) {
    /* CSS rules for screens larger than or equal to 900px */
    .loadingImage {
        width: 180px;
    }
}


.customTable {
    width: 100%;
}

.customTable .table
.customTable .MuiTable-root {
    border: none;
    text-align: right;
}

.customTable .MuiTableHead-root th {
    border-bottom: none;
}

.customTable .MuiTableCell-root {
    border-bottom: none;
}

.hide_text {
    --tw-blur: blur(4px);
    filter: var(--tw-blur);
}

.show_text {
}

